import sceneStore from "../../../../store/scenes/sceneStore";
import {useEffect, useRef} from "react";
import IconHotspot from "./hotspotComponents/IconHotspot";
import {useSceneBlock} from "../helpers/GroupBlock";
import {hotspotStoreApi} from "../../../../store/hotspots/hotspotStore";
import gsap from "gsap";
import videoStore from "../../../../store/videos/videoStore";
import {modalTypeStore} from "../../html/modal/Modal";

/**
 * maps hotspots from current Scene
 * @returns THREE.group of IconHotspot components
 */
function Hotspots() {
    const hotspots = sceneStore(state => state.sceneHotspots)
    const currentScene = sceneStore(state => state.currentScene)
    const play2D = videoStore(state => state.play2D)
    const fullScreenModal = modalTypeStore(state => state.fullScreenModal)
    const hotspotsRef = useRef()
    const {fadeOutGroup, fadeInGroup} = useSceneBlock()

    /**
     * Listen for hotspots changed
     * This is now based on scene states
     */
    useEffect(() => {

        if (!hotspots) return;
        // console.log('hotspots');
        fadeOutGroup(hotspotsRef.current, 0);
        fadeInGroup(hotspotsRef.current, 1, 1)
        // eslint-disable-next-line
    }, [hotspots])

    /**
     * Listen for change in scene
     * use this for hiding hotspots
     */
    useEffect(() => {
        if (!currentScene) return
        fadeOutGroup(hotspotsRef.current, 0)
        // eslint-disable-next-line
    }, [currentScene])

    useEffect(()=> {
        if (play2D || fullScreenModal) {
            fadeOutGroup(hotspotsRef.current, 0)
        } else {
            setTimeout(()=> {
                fadeInGroup(hotspotsRef.current, 1,0.03)
            }, 1500)
        }
    }, [play2D, fullScreenModal])

    return (
        <group ref={hotspotsRef}>
            {
                hotspots && hotspots.map((hotspot, index) => (
                    <IconHotspot key={index} hotspot={
                        hotspotStoreApi.getState().actions.getHotspotByID(hotspot.id)
                    }/>
                ))
            }
        </group>
    )
}

export default Hotspots
