/**
 * Scene store
 *
 */
import create from "zustand";
import {videoStoreApi} from "../videos/videoStore";
import {getValueByKey} from "../../utils/utilities";
import {audioStoreApi} from "../audio/audioStore";
import {uiStoreApi} from "../../experience/components/html/ui/uiStore";
import {doActions} from "../actionBuilderProcessing";
import {controlsStoreApi} from "../../experience/components/three/controls/CControls";

const [sceneStore, sceneStoreApi] = create((set, get) => ({
    allScenes: null,
    sceneHotspots: [],
    currentScene: null,
    currentState: null,
    actions: {
        /**
         * All scene data from GraphQL
         * @param scenes
         * scenes can stay the same
         * actions need processing
         */
        setAllScenes(scenes) {
            set(state => ({ allScenes: processScenes(scenes) }))

            /**
             * Set the initial properties of the scene
             * some values can be overwritten on state changes
             */
        },
        /**
         * @param id
         * @returns scene data
         */
        getScene(id) {
            return get().allScenes.find(x => x.id === id)
        },
        getAllScenes() {
            return get().allScenes
        },
        /**
         * Change the current scene
         * @param id
         * @param active
         */
        setMapVisibility(id, active) {
            const selectedScene = get().actions.getScene(id)
            // console.log('setMapVisibility', selectedScene)
            selectedScene.sceneMap = active
        },
        setScene(id) {
            const selectedScene = get().actions.getScene(id)
            set(state => ({ currentScene: selectedScene }))
            /**
             * Change the 360 video
             */

            // console.log('setscene 360', selectedScene.current.current360);
            videoStoreApi.getState().actions.play360(
                selectedScene.current.current360
            )
            /**
             * Check if videoElement has started playing
             * then add hotspots to scene
             */
            // console.log('', getSceneState(selectedScene).sceneStateHotspots);
            syncFader().then(()=> {
                set(state => ({
                    sceneHotspots: getSceneState(selectedScene).sceneStateHotspots
                }))
                /**
                 * State events
                 * also in setSceneState
                 */
                doOpenEvent(getSceneState(selectedScene))

                // camera focus
                if (getSceneState(selectedScene).cameraOrientation) {
                    // console.log('', getSceneState(selectedScene).cameraOrientation);
                    controlsStoreApi.getState().setTarget(getSceneState(selectedScene).cameraOrientation)
                }
            })


            if (selectedScene.current.currentAudio)
            {
                // console.log('playing audio', );
                audioStoreApi.getState().actions.playAudioByID(selectedScene.current.currentAudio)
            } else {
                console.log('stop audio');
                audioStoreApi.getState().actions.stopCurrentAudio()
            }
            // audioStoreApi.getState().actions.playAudioByID(selectedScene.current.currentAudio)
            uiStoreApi.getState().actions.setMenu(false)

        },
        /**
         * Change the state of the current scene
         */
        setSceneState(scene, state) {
            console.log('setSceneState', scene, state);
            // console.log('currentScene', get().currentScene.id);
            let targetScene = get().actions.getScene(scene)


            targetScene.currentState = state;

            // video


            if (getSceneState(targetScene).scene360) {
                // console.log('change', targetScene.current.current360, getSceneState(targetScene).scene360);
                targetScene.current.current360 = getSceneState(targetScene).scene360
                if (scene === get().currentScene.id) {
                    videoStoreApi.getState().actions.play360(
                        getSceneState(targetScene).scene360
                    )
                }
            } else {
                // console.log('no update to 360');
            }
            if (scene !== get().currentScene.id) {
                // setting other scene state
                // do not apply state changes to current scene
                return
            }
            /**
             * Events
             */
            doOpenEvent(getSceneState(targetScene))
            /**
             * hotspots
             */
            set(state => ({
                sceneHotspots: getSceneState(targetScene).sceneStateHotspots
            }))
            set(state => ({ currentState: Date.now() }))
            /**
             * Update scene properties
             */

            // audio
            if (getSceneState(targetScene).sceneAudio) {
                // console.log('audio', getSceneState(targetScene).sceneAudio);
                audioStoreApi.getState().actions.playAudioByID(getSceneState(targetScene).sceneAudio)
                targetScene.current.sceneAudio = getSceneState(targetScene).sceneAudio
            } else {
                console.log('no update to audio');
            }

            // camera focus
            if (getSceneState(targetScene).cameraOrientation) {
                // console.log('', getSceneState(targetScene).cameraOrientation);
                controlsStoreApi.getState().setTarget(getSceneState(targetScene).cameraOrientation)
            }
        },
        endBoomerang() {
            videoStoreApi.getState().actions.play360(
                get().currentScene.current.current360
            )
            // console.log('', get().currentScene.current.current360);
        },
        getHelp() {
            // called from helpStore
            // check if help is available for this state
            let help = getSceneState(get().currentScene).help;
            return(help)
        },
        doVideoRestart() {
            // TODO
            try {
                let state = getSceneState(get().currentScene)
                if (state.events.EVENT_360_END) {
                    doEnd360Event(state.events.EVENT_360_END)
                }
            } catch (e) {
                console.log('', e);
            }

        }
    }
}))
export default sceneStore;
export {sceneStoreApi}

async function syncFader() {
    let currentVideo = videoStoreApi.getState().videoElement
    while (currentVideo.currentTime <= 0) {
        await new Promise(resolve => {
            requestAnimationFrame(resolve);
        })
    }
}


function processScenes(scenes) {
    let allProcessed = []

    scenes.forEach(scene => {
        let processedScene = {}
        processedScene.current = {}
        processedScene.id = scene.id
        processedScene.title = scene.title
        processedScene.sceneMap = scene.sceneMap
        processedScene.sceneDescription = scene.sceneDescription
        processedScene.states = []
        scene.sceneStates.forEach(state => {
            let processedState = {}
            processedState.id = state.id
            processedState.sceneDescription = state.sceneDescription && state.sceneDescription
            processedState.scene360 = state.scene360[0] && state.scene360[0].id
            processedState.sceneAudio = state.sceneAudio[0] && state.sceneAudio[0].id
            processedState.sceneHandle = state.sceneHandle
            processedState.sceneStateHotspots = state.sceneStateHotspots
            processedState.events = processEvents(state.events)
            processedState.help = state.stateHelp[0] && state.stateHelp[0].id
            // processedState.cameraOrientation = state.cameraOrientation ? state.cameraOrientation[0] : null
            if (state.cameraOrientation && state.cameraOrientation[0].x) {
                processedState.cameraOrientation = state.cameraOrientation[0]
            } else {
                processedState.cameraOrientation = {x: 0, y: 0,z: 0}
            }

            // Add videos to preload
            if (processedState.scene360) {
                videoStoreApi.getState().actions.addActiveVideo(processedState.scene360)
            }


            processedScene.states.push(processedState)
        })
        processedScene.currentState = scene.sceneStates[0].sceneHandle

        /**
         * set initial properties of scene.current
         */
        processedScene.current.current360 = scene.sceneStates[0].scene360.length && scene.sceneStates[0].scene360[0].id
        if (scene.sceneStates[0].sceneAudio.length) {
            processedScene.current.currentAudio = scene.sceneStates[0].sceneAudio[0].id
        }
        allProcessed.push(processedScene)
    })
    return allProcessed;
}

function processEvents(events) {
    // console.log('', events);
    let processedEvents = {}
    events.forEach(event => {
        switch (event.event) {
            case "stateOpen":
                processedEvents.EVENT_OPEN =  event.eventActions[0]
                break
            case "stateOpenFirst":
                processedEvents.EVENT_OPEN_FIRST =  event.eventActions[0]
                break
            case "on360End":
                processedEvents.EVENT_360_END =  event.eventActions[0]
                break
            default:
        }
    })
    return processedEvents
}

function getSceneState(selectedScene) {
    return getValueByKey(selectedScene.states, "sceneHandle", selectedScene.currentState)
}

function doOpenEvent(state) {
    // console.log('state', state);
   if (state.events.EVENT_OPEN_FIRST && !state.events.EVENT_OPEN_FIRST.fired) {
       state.events.EVENT_OPEN_FIRST.fired = true
       doActions(state.events.EVENT_OPEN_FIRST.actions)
   }
   if (state.events.EVENT_OPEN) {
       doActions(state.events.EVENT_OPEN.actions)
   }
}
function doEnd360Event(event) {
    // console.log('end 360 ', event);
    doActions(event.actions)
}
