import ShaderCircle from "./shadercircle/ShaderCircle";
import {Billboard} from "@react-three/drei";
import {extend} from '@react-three/fiber'
import {Text} from "troika-three-text";
import {debounce} from "debounce";
import {useRef, memo, useState, useEffect, Suspense} from "react";
// import {actionsStoreApi} from "../../../../../store/actions/actionStore";
import * as THREE from "three";
import {doActions} from "../../../../../store/actionBuilderProcessing";
import Icon from "./icon/Icon";
import {cameraStore, controlsStore} from "../../controls/CControls";
import {experienceStore} from "../../SceneManager";
import audioStore from "../../../../../store/audio/audioStore";
import globalSetStore, {globalSetStoreAPI} from "../../../../../store/globalSets/globalSetStore";
// import {hotspotStoreApi} from "../../../../../store/hotspots/hotspotStore";
extend({Text});

/**
 *
 * @param hotspot
 * All we need here is the location xyz
 * https://stackoverflow.com/questions/45927235/change-color-of-an-svg-dynamically-and-apply-as-texture-in-three-js
 */
function IconHotspot({hotspot}) {

    const [actionsDisplay, setActionsDisplay] = useState()
    const [hide, setHide] = useState(false)
    const setTarget = controlsStore(state => state.setTarget)
    const is2D = experienceStore(state => state.is2D)
    const audioActions = audioStore(state => state.actions)
    const sfx = globalSetStore(state => state.sfx)


    useEffect(()=> {
        if (!hotspot) return;
        let actions = [];
        hotspot.actions.forEach(action => {
            actions.push(`${Object.values(action)}`)
        })
        setActionsDisplay(hotspot.title)
    }, [hotspot])

    const [hovered, setHover] = useState(false)
    useEffect(() => {
        document.body.style.cursor = hovered ? 'pointer' : 'auto';
        if (hovered) {
            document.body.classList.add('hOver')
        } else {
            document.body.classList.remove('hOver')
        }
    }, [hovered]);

    useEffect(()=> {
        if (is2D) {
            setHover(false)
        }
    }, [is2D])

    const onClick = () => {
        // check hide on click
        if (hotspot.hotspotHideOnClick) {
            setHide(true)
        }
        // sfx
        audioActions.playAudioByID( sfx.sfxClick )
        // move camera
        setTarget(hotspot.hotspotLocation[0])
        // actions
        doActions(hotspot.actions)
    }
    return (
        <group position={getCoords(hotspot)} scale={5}>
            {
                (!is2D && !hide) ?
                    <Billboard
                               follow={true} // Follow the camera (default=true)
                               lockX={false} // Lock the rotation on the x axis (default=false)
                               lockY={false} // Lock the rotation on the y axis (default=false)
                               lockZ={false} // Lock the rotation on the z axis (default=false)
                               onClick={debounce(onClick, 200)}
                               onPointerOver={(event) => setHover(true)}
                               onPointerOut={(event) => setHover(false)}
                    >
                        {/*<ShaderCircle ref={circRef} spin={true} animated={false} scale={3.1}
                              color={'#ffffff'}
                              alpha={0.03}
                              innerRadius={0.57} outerRadius={1.0} rotation={[0, 0, 0]}/>*/}
                        {/*<text
                    text={actionsDisplay}
                    anchorX="left"
                    anchorY="middle"
                    fontSize={2.3}
                    textAlign={'left'}
                    // font={font}
                    maxWidth={123}
                    userData={{active: true, savedOpacity: 0.8}}
                    position={[5,0,0]}
                >
                    <meshBasicMaterial attach="material" color={"#ffffff"}
                                       transparent={true} blending={THREE.AdditiveBlending}
                                       opacity={0.8}/>
                </text>*/}
                        <Suspense fallback={null}>
                            <Icon hotspot={hotspot} />
                        </Suspense>
                    </Billboard>
                    : null
            }

        </group>
    )
}

export default memo(IconHotspot)

function getCoords(hotspot) {
    // console.log('', hotspot);
    return (
        [
            parseFloat(hotspot.hotspotLocation[0].x),
            parseFloat(hotspot.hotspotLocation[0].y),
            parseFloat(hotspot.hotspotLocation[0].z)
        ]
    )
}
