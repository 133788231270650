import {ShaderMaterial, TextureLoader, sRGBEncoding} from "three"
import {useEffect, useMemo, useState} from "react"
import * as THREE from 'three'
import videoStore from "../../../../store/videos/videoStore";
import gsap from "gsap";
function Sphere360() {
    const texture2 = new TextureLoader().load('blank.png')
    const videoTexture = videoStore(state => state.videoTexture)
    const play2D = videoStore(state => state.play2D)
    const [texture] = useState(texture2)
    useEffect(()=> {
        if (!texture) return;
        texture.encoding = sRGBEncoding;
    }, [texture])

    useEffect(()=> {
        if (!videoTexture) return
        shaderMaterial.uniforms.u_Txt1.value = videoTexture
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoTexture])

    useEffect(()=> {

        const o = {alpha: 0}
        if (play2D) {
            shaderMaterial.uniforms.u_Opacity.value = 0
        } else {
            shaderMaterial.transparent = true
            shaderMaterial.uniforms.u_Opacity.value = 0
            setTimeout(()=> {
                shaderMaterial.uniforms.u_Opacity.value = 1
                shaderMaterial.transparent = false
            }, 500)
        }
    }, [play2D])

    const shaderMaterial = useMemo(
        () =>
            new ShaderMaterial({
                side: THREE.DoubleSide,
                transparent: false,
                fragmentShader: `
                uniform sampler2D u_Txt1;
                varying vec2 vUv;
                uniform float u_Opacity; 
                void main() {
                  gl_FragColor = mapTexelToLinear( texture2D(u_Txt1, vUv) );
                  gl_FragColor = vec4(gl_FragColor.rgb, u_Opacity);
                  #include <encodings_fragment>
                }`,
                uniforms: {
                    u_Txt1: { value: texture },
                    u_Opacity: {type: "f", value: 1.0}
                },
                vertexShader: `#include <common>
                varying vec2 vUv;
                void main () {
                  vUv = uv;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`
            }),
        [texture]
    );
    shaderMaterial.map = texture

    return (
        <group visible={!play2D}>
            <mesh
                renderOrder={0}
                scale={[-1,1,1]}
                material={shaderMaterial}
            >
                <sphereBufferGeometry attach="geometry" args={[850, 32, 32]}/>
            </mesh>
        </group>
    )
}

export default Sphere360
