import {memo, useEffect, useRef} from "react";
import * as THREE from "three";
import {useLoader} from "@react-three/fiber";

const Icon = memo(({hotspot})=> {

    const texture = useLoader(THREE.TextureLoader, hotspot.icon.url)
    const meshRef = useRef()
        // console.log('', texture);
    return (
        <mesh
            scale={0.05}
            ref={meshRef}
            userData={{savedOpacity: hotspot.icon.hotspotOpacity ? hotspot.icon.hotspotOpacity : 1}}
        >
            <planeBufferGeometry args={[128, getHeight(texture.image)]}/>
            <meshBasicMaterial side={THREE.DoubleSide} map={texture} opacity={hotspot.icon.hotspotOpacity ? hotspot.icon.hotspotOpacity : 1} transparent={true}/>
        </mesh>
    )
})
export default Icon

function getHeight(image) {
    return (image.naturalHeight / image.naturalWidth) * 128
}
