import CookieConsent from "react-cookie-consent";
import {useEffect, useState} from "react";
import siteStore from "../store/siteStore";
import {getCookie} from "../utils/utilities";
import InnerHTML from "dangerously-set-html-content";
import globalSetStore from "../store/globalSets/globalSetStore";
import Interweave from "interweave";

function CookiesAndTracking() {

    const [showCookie, setShowCookie] = useState(true)
    const dropCookie = siteStore(state => state.dropCookie)
    const actions = siteStore(state => state.actions)
    const globalSets = globalSetStore(state => state.globalSets)
    function onAcceptCookie() {
        actions.setDropCookie(true)
        setShowCookie(false)
    }

    function onDeclineCookie() {
        actions.setDropCookie(false)
        setShowCookie(false)
    }

    useEffect(() => {
        let c = getCookie("CookieConsent");
        if (c === "true") {
            // console.log('dropping cookie', );
            actions.setDropCookie(true)
            setShowCookie(false)
        }
        if (c === "false") {
            console.log('dont dropping cookie', );
            actions.setDropCookie(false)
            setShowCookie(false)
        }
    }, [])

    return (
        <>

            {/*<Tracking/>*/}

            {
                dropCookie ?
                    <InnerHTML html={html}/>
                    : null
            }
            <div className="ccwrapper" style={{display: showCookie ? "flex" : "none"}}>
                {
                    globalSets ?
                        <CookieConsent buttonText="Accept" declineButtonText="Decline" onAccept={onAcceptCookie}
                                       onDecline={onDeclineCookie} enableDeclineButton flipButtons>
                            <Interweave content={globalSets.cookieConsent}/>
                        </CookieConsent>
                        : null
                }
            </div>

        </>
    )
}
export default CookiesAndTracking

const html = `
<!-- Global site tag (gtag.js) - Google Analytics -->
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-KH0Q0CF5QR"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KH0Q0CF5QR');
        </script>
`
