import Hls from "hls.js";

function createHLSObject(preload) {
    // console.log('creating HLS');

    const config = {
        startFragPrefetch: true,
        maxBufferLength: 1,
        startPosition: 0 // important for restarting HLS to beginning, currentTime won't work
    }

    const hls = new Hls(config);
    hls.startLevel = hls.levels.length-1;
    hls.nextLevel = hls.levels.length-1;

    /**
     * Cache in blob
     */

    hls.on(Hls.Events.BACK_BUFFER_REACHED, ()=> {
        //console.log('BACK_BUFFER_REACHED');
    });
    /**
     * This fires if startFragPrefetch is true
     */
    hls.on(Hls.Events.FRAG_LOADING, (e)=> {
        // console.log('loaded', );
        // managerApi.getState().managerActions.setLoadedFrags()
    });

    hls.on(Hls.Events.ERROR, function (event, data) {
        // console.log('', event, data.details);
    });

    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        //console.log('video and hls.js are now bound together !');
        // hls.loadSource('https://content.uplynk.com/ext/174d67f3828e4cdc8b52c8ee6e90ad27/0.m3u8');
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            /*console.log(
                'manifest loaded, found ' + data.levels.length + ' quality level'
            );*/
        });
        hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
            // console.log('level change', data.level);
            hls.nextLevel = hls.levels.length-1;
        });
        hls.on(Hls.Events.BUFFER_APPENDING, function (eventName, data) {
            // console.log('appending', hls);
            // mainVideoData.current[data.type].push(data.data);
        });
        hls.on(Hls.Events.BUFFER_RESET, function (eventName, data) {
            console.log('BUFFER_RESET');
        });
    });

    return hls;
}

export {createHLSObject}
