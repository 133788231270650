import {Canvas} from "@react-three/fiber";
import { ResizeObserver } from '@juggle/resize-observer'; // safari polyfill
import * as THREE from 'three'
import {useRef, useState} from "react";
import {Stats} from "@react-three/drei"
// import DebugBox from "../../../debug/DebugBox";
import {Lights} from "../lights/Lights";
import Sphere360 from "../sphere360/Sphere360";
import CControls from "../controls/CControls";
import Hotspots from "../hotspots/Hotspots";
import Video2D from "../video2D/Video2D";
import {useKeyPress} from "../../../../utils/hooks";
import HotspotCreator from "./HotspotCreator";
import Postprocessing from "../effects/Postprocessing";
import DebugBox from "../../../debug/DebugBox";

function ThreeEnvironment() {
    // console.log('render');
    const [showHotspots, setShowHotspots] = useState(false);
    useKeyPress("h", () => {
        if (window.isDebug) {
            setShowHotspots(true)
        }
    })
    useKeyPress("j", () => {
        if (window.isDebug) {
            setShowHotspots(false)
        }
    })
    const canvasRef = useRef();

    return (
        <Canvas sRGB={true} resize={{polyfill: ResizeObserver}} ref={canvasRef}
                camera={{far: 2300, position: [0, 0, -10.1], fov: 70}}
                gl={{alpha: false, antialias: false}}
                onCreated={({gl}) => {
                    gl.toneMapping = THREE.NoToneMapping
                    gl.outputEncoding = THREE.sRGBEncoding
                }}>
            {/*<Stats showPanel={0} className="stats" />*/}
            <Lights />
            <Sphere360 />
            <Video2D />
            <Hotspots />
            {
                showHotspots ?
                    <HotspotCreator/>
                    : null
            }
            <color attach="background" args={[new THREE.Color("#000000").convertSRGBToLinear()]} />
            {/*<DebugBox />*/}
            <CControls />
            <Postprocessing />
        </Canvas>
    )
}
export default ThreeEnvironment
