import "./_modal.scss"
import modalStore from "../../../../store/modals/modalStore"
import {useEffect, useRef} from "react"
import create from "zustand";
import ModalContent from "./types/ModalContent";
import ModalActions from "./modalcomponents/ModalActions";
import ModalVideo from "./types/ModalVideo";
import ModalModel from "./types/ModalModel";
import ModalGallery, {modalGalleryStore} from "./types/ModalGallery";
import bt_close from "../../../../assets/icons/svg/close_dark.svg"
import close_icon from "../../../../assets/icons/svg/close_2.svg"
import SwiperCore, {
    Navigation
} from 'swiper';
import themeStore from "../../../../store/themes/themeStore";
import {useModalScaling} from "../../../../utils/hooks";
import SVG from "react-inlinesvg";
import globalSetStore from "../../../../store/globalSets/globalSetStore";
import audioStore from "../../../../store/audio/audioStore";
import gsap from "gsap"
import ModalCollectable from "./types/ModalCollectable";
import UIIcon from "../ui/components/UIIcon";

const TYPE_CONTENT = "content"
const TYPE_VIDEO = "video"
const TYPE_GALLERY = "gallery"
const TYPE_MODEL = "model"
const TYPE_COLLECTABLE = "collectable"
SwiperCore.use([Navigation]);
export const [modalTypeStore, modalTypeStoreApi] = create((set, get) => ({
    type: 0,
    content: 0,
    scaling: true,
    config: {},
    fullscreenVideo: {},
    videoEnded: null,
    fullScreenModal: null,

    setType: (v) => set({type: v}),
    setContent: (v) => set({content: v}),
    setConfig: (v) => set({config: v}),
    setScaling: (v) => set({scaling: v}),
    setFullscreenVideo: (v) => set({fullscreenVideo: v}),
    setVideoEnded: (v) => set({videoEnded: Date.now()}),
    setFullScreenModal: (v) => set({fullScreenModal: v})
}))

function Modal() {
    const modalActions = modalStore(state => state.actions)
    const closeModal = modalStore(state => state.closeModal)
    const openModal = modalStore(state => state.openModal)
    const actions = modalStore(state => state.actions)
    const type = modalTypeStore(state => state.type)
    const setType = modalTypeStore(state => state.setType)
    const content = modalTypeStore(state => state.content)
    const setContent = modalTypeStore(state => state.setContent)
    const setFullscreenVideo = modalTypeStore(state => state.setFullscreenVideo)
    const config = modalTypeStore(state => state.config)
    const setConfig = modalTypeStore(state => state.setConfig)
    const setScaling = modalTypeStore(state => state.setScaling)
    const videoEnded = modalTypeStore(state => state.videoEnded)
    const scaling = modalTypeStore(state => state.scaling)
    const setFullScreenModal = modalTypeStore(state => state.setFullScreenModal)
    const globalSets = globalSetStore(state => state.globalSets)
    const audioActions = audioStore(state => state.actions)
    const sfx = globalSetStore(state => state.sfx)
    const helpTimer = useRef()
    const modalRef = useRef()
    const modalAnimate = useRef()
    const modalMobile = useRef()
    const panelRef = useRef()

    function onClose() {
        modalActions.setCloseModal()
        audioActions.playAudioByID(sfx.sfxClose)
    }

    useEffect(() => {
        if (!closeModal) return;
        clearTimeout(helpTimer.current)
        modalRef.current.style.display = "none"
        setContent(null)
        setType(null)
        setFullScreenModal(false)
    }, [closeModal])

    useEffect(() => {
        if (!openModal) return;
        /**
         * Config used for help
         */
        // console.log('openModal', openModal);
        if (openModal.config) {
            setConfig(openModal.config)
        } else {
            setConfig(null)
        }
        modalRef.current.style.display = "flex"
        setType(openModal.content.type)
        setContent(openModal.content.copy)

        gsap.set(modalAnimate.current, {opacity: 0, scale: 0.92})
        gsap.to(modalAnimate.current, {duration: 0.25, opacity: 1, delay: 0.2})
        gsap.to(modalAnimate.current, {duration: 0.25, scale: 1, delay: 0.2, ease: "expo.out"})

        /**
         * Help timer
         */
        if (openModal.config.isHelp) {
            helpTimer.current = setTimeout(() => {
                actions.getNextModal()
            }, globalSets.experience.helpTiming)
        } else {
            clearTimeout(helpTimer.current)
        }

        /**
         * Set responsive or not
         */
        setScaling(!openModal.content.modalResponsive)
    }, [openModal])

    useEffect(() => {
        if (!videoEnded) return
        console.log('videoEnded', videoEnded);
        if (openModal.config.isHelp) {
            actions.getNextModal()
        }
    }, [videoEnded])


    useModalScaling(panelRef, type)

    function ContentType() {
        // console.log('', openModal);
        if (openModal) {
            if (type === TYPE_GALLERY || openModal.content.modalVideoFull) {
                setScaling(false)
            }
            if (openModal.content.modalVideoFull) {
                setFullscreenVideo(true)
            } else {
                setFullscreenVideo(false)
            }
        }

        switch (type) {
            case TYPE_CONTENT:
                return <ModalContent content={content}/>
            case TYPE_VIDEO:
                /**
                 * If the modal has a video and is also help
                 * need to stop helptimer
                 */
                clearTimeout(helpTimer.current)
                return <ModalVideo content={openModal.content}/>
            // videoID
            case TYPE_GALLERY:
                return <ModalGallery galleryID={openModal.content.galleryID}/>
            case TYPE_MODEL:
                if (!openModal.content.enableBackground) {
                    setFullScreenModal(true)
                }
                return <ModalModel content={openModal.content}/>
            case TYPE_COLLECTABLE:
                return <ModalCollectable content={openModal.content}/>
            default:
                return null
        }
    }

    function onModalClick() {
        if (config.isHelp) {
            clearTimeout(helpTimer.current)
            actions.getNextModal()
        }
    }

    return (
        <div className="modaldev__container" ref={modalRef} style={{display: "none"}}>
            <div className={`ui__icon ui__tr2 close_3d`} onClick={onClose}>
                <UIIcon asset={bt_close} />
            </div>

            <div className="modaldev__mobilewrapper" ref={modalMobile}>
                <div className="modaldev__animate" ref={modalAnimate}>
                    <div
                        className={`modaldev__panel 
                ${scaling ? "scaling" : "no-scaling"}  
                ${openModal && openModal.content.modalColour === "light" ? "col-primary" : "col-secondary"}  
                ${openModal && openModal.content.modalVideoFull ? "fullvideo" : ""}`}
                        style={{textAlign: `${openModal ? openModal.content.modalAlign : 'left'}`}}
                        ref={panelRef} onClick={onModalClick}>
                        {
                            !config.isHelp ?
                                <div className="modaldev__close">
                                    <SVG src={bt_close} onClick={onClose}/>
                                </div>
                                : null
                        }
                        <ContentType/>
                        <div>

                            {
                                (config.isHelp && config.total > 1) ?
                                    <div className={`helpcircles ${openModal && openModal.content.modalColour}`}>
                                        {[...Array(config.total)].map((e, i) => (
                                                <div key={i}
                                                     className={`helpcircle ${config.index === i ? "helpcircle--active" : ''}`}
                                                />
                                            )
                                        )}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="hideModalButtons">
                            {
                                openModal && openModal.content.buttons ?
                                    <ModalActions buttons={openModal.content.buttons}
                                                  direction={openModal.content.buttonDirection}/>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="modaldev__bgclick" onClick={onClose}/>
                </div>
            </div>
        </div>
    )
}

export default Modal
