import {ShaderMaterial, TextureLoader, sRGBEncoding} from "three"
import {useEffect, useMemo, useRef, useState} from "react"
import videoStore from "../../../../store/videos/videoStore"
import {useFrame, useThree} from "@react-three/fiber"
import * as THREE from 'three'
import gsap from "gsap"
import create from "zustand"

const [video2DStore, video2DApi] = create((set, get) => ({
    opacity: 0.6,
    setOpacity: (v) => set({opacity: v})
}))

function Video2D() {
    const texture2 = new TextureLoader().load('blank.png')
    const videoTexture = videoStore(state => state.videoTexture)
    const play2D = videoStore(state => state.play2D)
    const videoPlaying = videoStore(state => state.videoPlaying)
    const opacity = video2DStore(state => state.opacity)
    const setOpacity = video2DStore(state => state.setOpacity)

    const {camera, viewport} = useThree()
    const planeRef = useRef()
    const thisRef = useRef()
    const [texture] = useState(texture2)
    useEffect(()=> {
        if (!texture) return;
        texture.encoding = sRGBEncoding;
    }, [texture])
    useEffect(()=> {
        if (!videoTexture) return;
        shaderMaterial.uniforms.u_Txt1.value = videoTexture;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoTexture])

    useEffect(()=> {
        const o = {alpha: 0};
        // console.log('play2D', play2D);
        if (play2D) {
            shaderMaterial.uniforms.u_Opacity.value = 0
        } else {
            shaderMaterial.uniforms.u_Opacity.value = 0
        }

    }, [play2D])

    useEffect(()=> {
        // console.log('v', videoPlaying);
        gsap.to(shaderMaterial.uniforms.u_Opacity, {duration: 0.3, value: 1, delay: 0.3})
    }, [videoPlaying])

    useFrame(() => {
        thisRef.current.quaternion.copy(camera.quaternion)
        resize();
    })
    function resize(v = 0.1) {
        const aspect = 1.77778;
        if (!texture) return
        if ((viewport.width / aspect) < viewport.height) {
            planeRef.current.scale.set(
                viewport.height * aspect,
                viewport.height,
                0.11
            )
        } else {
            planeRef.current.scale.set(
                viewport.width,
                viewport.width / 1.7778,
                0.1
            )
        }
    }

    const shaderMaterial = useMemo(
        () =>
            new ShaderMaterial({
                transparent: true,
                side: THREE.DoubleSide,
                fragmentShader: `
                uniform sampler2D u_Txt1;
                uniform float u_Opacity; 
                varying vec2 vUv;
                void main() {
                  gl_FragColor = mapTexelToLinear( texture2D(u_Txt1, vUv) );
                  gl_FragColor = vec4(gl_FragColor.rgb, u_Opacity);
                  #include <encodings_fragment>
                }`,
                uniforms: {
                    u_Txt1: { value: texture },
                    u_Opacity: {type: "f", value: 1.0},
                },
                vertexShader: `#include <common>
                varying vec2 vUv;
                void main () {
                  vUv = uv;
                  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`
            }),
        [texture]
    );
    shaderMaterial.map = texture

    return (
        <group ref={thisRef} position={[0, 0, 0]} visible={play2D}>
            <mesh ref={planeRef} material={shaderMaterial}>
                {/*<meshStandardMaterial color='red' side={THREE.DoubleSide} opacity={0.5} transparent/>*/}
                <boxBufferGeometry attach="geometry" args={
                    [
                        1,
                        1,
                        0.1
                    ]
                }/>
            </mesh>
        </group>
    )
}

export default Video2D
