import React, {memo, forwardRef, useImperativeHandle, useRef} from "react";
import "./_previewVideo.scss";


const PreviewVideo = memo(forwardRef(({...props}, ref) => {
    const vidRef = useRef()
    useImperativeHandle(
        ref,
        () => ({
            setMedia: (v) => {

                v.hls.attachMedia(vidRef.current)
                vidRef.current.play()
            },
            detach: (v)=> {
                v.hls.detachMedia(vidRef.current)
                console.log('', v);
            }
        }),
    )

    return (
        <div className="previewVideo">
            <video ref={vidRef} muted={true} playsInline loop />
        </div>
    )
}));
export default PreviewVideo
